import * as React from "react";
import Button from "../components/elements/Button";
import Layout from "../components/layout";
import Background from "../images/bg_waves_lighter.png";

const NotFoundPage = () => {
  return (
    <>
      <Layout>
        <div
          className="container fullwidth"
          style={{
            background: `url(${Background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% auto",
            backgroundPosition: "top",
            alignItems: 'center'
          }}
        >
          <div className="wrapper404 container reverse-mobile">
            <div className="col5">
              <div>
                <h2 style={{ marginBottom: 40 }}>
                  404 –<br />
                  Seite nicht gefunden
                </h2>

                <p style={{ marginBottom: 45 }}>
                  Oops, da ist uns leider ein Fehler unterlaufen, dieser Bereich ist top secret 😉, kommen Sie gerne zurück zu unserer Startseite!
                </p>

                <Button type="primary" text="Zur Startseite" destination="/" />
              </div>
            </div>
            <div className="col2" />
            <div className="col5">
              <img style={{ objectFit: 'contain' }} src="https://go4digital.cc/wp/wp-content/uploads/2022/01/SMV5445-sw-scaled.jpg" alt="" />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default NotFoundPage;
